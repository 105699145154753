import { makeStyles } from '@material-ui/core/styles';
import { SECONDARY, GRAY_200, GRAY_400 } from '@src_theme/colors';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import ButtonBase from '@material-ui/core/ButtonBase';
import { debounce } from 'lodash';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        position: 'relative',
    },
    nav: {
        display: 'none',
        '@media (min-width: 768px)': {
            display: 'block',
        },
        '& > *': {
            position: 'absolute',
            top: 'calc(50% - 1.5rem)',
            width: 32,
            height: 32,
            borderRadius: 9999,
            overflow: 'hidden',
            color: GRAY_400,
            backgroundColor: 'white',
            '&:hover:not(:disabled)': {
                backgroundColor: SECONDARY,
                color: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            },
            '&:disabled': {
                visibility: 'hidden',
                cursor: 'default',
            },
            '& > *': {
                fontSize: 20,
            },
        },
        '& > :first-child': {
            left: -28,
            transform: 'rotate(180deg)',
            boxShadow: `0px -1px 1px ${GRAY_200}`,
        },
        '& > :last-child': {
            right: -28,
            boxShadow: `0px 1px 1px ${GRAY_200}`,
        },
    },
    scrollContainer: {
        display: 'flex',
        gap: '8px',
        overflowX: 'auto',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        scrollSnapType: 'x mandatory',
        '@media (min-width: 768px)': {
            gap: '16px',
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '& > *': {
            flexShrink: 0,
            scrollSnapAlign: 'start',
        },
    },
});

export default function CarouselV2({
    children, displayArrows = true, rootProps = {}, scrollContainerProps = {},
    handleHideImage = () => null, handleShowImage = () => null,
}) {
    const styles = useStyles();
    const scrollContainer = useRef(null);
    const [disableLeftArrow, setDisableLeftArrow] = useState(true);
    const [disableRightArrow, setDisableRightArrow] = useState(false);

    function handleLeftEdge() {
        setDisableLeftArrow(true);
        setDisableRightArrow(false);
        handleShowImage();
    }

    function handleRightEdge() {
        setDisableLeftArrow(false);
        setDisableRightArrow(true);
    }

    function handleCenter() {
        setDisableLeftArrow(false);
        setDisableRightArrow(false);
        handleHideImage();
    }

    function handleDisableArrows() {
        setDisableLeftArrow(true);
        setDisableRightArrow(true);
    }

    function checkScrollPosition() {
        if (!scrollContainer.current) return;

        const onEdgeLeft = scrollContainer.current.scrollLeft <= 1;
        const onEdgeRight = scrollContainer.current.scrollLeft >= scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth - 1;
        const onCenter = !onEdgeLeft && !onEdgeRight;
        const onDisable = scrollContainer.current.scrollWidth <= scrollContainer.current.clientWidth;

        if (onDisable) {
            handleDisableArrows();
        } else if (onEdgeLeft) {
            handleLeftEdge();
        } else if (onEdgeRight) {
            handleRightEdge();
        } else if (onCenter) {
            handleCenter();
        }
    }

    function scroll(amount) {
        scrollContainer.current.scrollBy({ left: amount, behavior: 'smooth' });
    }

    const debouncedCheckScrollPosition = useCallback(debounce(checkScrollPosition, 10), [scrollContainer.current]);

    const debouncedScrollLeft = useCallback(
        debounce(() => scroll(-scrollContainer.current.clientWidth), 500, { leading: true, maxWait: 500 }),
        [scrollContainer.current],
    );

    const debouncedScrollRight = useCallback(
        debounce(() => scroll(scrollContainer.current.clientWidth), 500, { leading: true, maxWait: 500 }),
        [scrollContainer.current],
    );

    useEffect(() => debouncedCheckScrollPosition(), [children]);

    const { className: rootClassName, ...otherRootProps } = rootProps;
    const { className: scrollContainerClassName, ...otherScrollContainerProps } = scrollContainerProps;

    return (
        <div className={classNames(styles.root, rootClassName)} {...otherRootProps}>
            <div
                className={classNames(styles.scrollContainer, scrollContainerClassName)}
                ref={scrollContainer}
                onScroll={debouncedCheckScrollPosition}
                {...otherScrollContainerProps}
            >
                {children}
            </div>
            {displayArrows && (
                <div className={styles.nav}>
                    <ButtonBase disabled={disableLeftArrow} onClick={debouncedScrollLeft}>
                        <RightArrowIcon />
                    </ButtonBase>
                    <ButtonBase disabled={disableRightArrow} onClick={debouncedScrollRight}>
                        <RightArrowIcon />
                    </ButtonBase>
                </div>
            )}
        </div>
    );
}
